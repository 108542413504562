.ah-wave {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ah-wave .ah-wave-bar {
  background: white;
  width: 2px;
  margin-right: 2px
}

.ah-wave .ah-wave-bar:nth-child(1) {
  animation: wave 2.5s 1.4s infinite linear;
}

.ah-wave .ah-wave-bar:nth-child(2) {
  animation: wave 2.5s 1.2s infinite linear;
}

.ah-wave .ah-wave-bar:nth-child(3) {
  animation: wave 2.5s 1s infinite linear;
}

.ah-wave .ah-wave-bar:nth-child(4) {
  animation: wave 2.5s 0.8s infinite linear;
}

.ah-wave .ah-wave-bar:nth-child(5) {
  animation: wave 2.5s 0.6s infinite linear;
}

.ah-wave .ah-wave-bar:nth-child(6) {
  animation: wave 2.5s 0.4s infinite linear;
}

.ah-wave .ah-wave-bar:nth-child(7) {
  animation: wave 2.5s 0.2s infinite linear;
}

.ah-wave .ah-wave-bar:nth-child(8) {
  animation: wave 2.5s 0s infinite linear;
}

.ah-wave .ah-wave-bar:nth-child(9) {
  animation: wave 2.5s 0.2s infinite linear;
}

.ah-wave .ah-wave-bar:nth-child(10) {
  animation: wave 2.5s 0.4s infinite linear;
}

.ah-wave .ah-wave-bar:nth-child(11) {
  animation: wave 2.5s 0.6s infinite linear;
}

.ah-wave .ah-wave-bar:nth-child(12) {
  animation: wave 2.5s 0.8s infinite linear;
}

.ah-wave .ah-wave-bar:nth-child(13) {
  animation: wave 2.5s 1s infinite linear;
}

.ah-wave .ah-wave-bar:nth-child(14) {
  animation: wave 2.5s 1.2s infinite linear;
}

.ah-wave .ah-wave-bar:nth-child(15) {
  animation: wave 2.5s 1.4s infinite linear;
  margin-right: 0;
}

@keyframes wave {
  0% {
    height: 10%;
  }

  50% {
    height: 100%;
  }

  100% {
    height: 10%;
  }
}